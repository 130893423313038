import CookieConsent from 'react-cookie-consent';
import { useCookies } from 'react-cookie';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export const Footer = () => {

  const [cookie, setCookie, removeCookie] = useCookies(['CookieConsent']);
  const refresh = () => window.location.reload(true);

  return (
    <>
      <div className="container-fluid pt-3 g-0" style={{ backgroundColor: '#444444' }}>
        <Row>

          <Col>
            <div style={{ color: '#94D7B5' }} >PER FINANZIAMENTI</div>

            <ul>
              <li><a style={{ color: '#ffffff' }} href="https://farbanca.it/" target="_blank" rel="noopener">Farbanca</a></li>
              <li><a style={{ color: '#ffffff' }} href="https://www.credifarma.it/" target="_blank" rel="noopener">Credifarma</a></li>
              <li><a style={{ color: '#ffffff' }} href="https://www.bancaifis.it/" target="_blank" rel="noopener">Banca Ifis</a></li>
            </ul>
            <button style={{ backgroundColor: '#444444', color: '#94D7B5' }}  onClick={() => {removeCookie('CookieConsent'); refresh()}}>Gestione cookies</button>
          </Col>

          <Col>
            <div style={{ color: '#94D7B5' }} >INFORMAZIONI</div>
            <div style={{ padding: '0px,30px,0px,30px' }}>
              <strong><span style={{ color: '#ffffff' }}>Farma Contatto S.a.s.<br></br>
                di Achille Bonzini &amp; C.<br></br>
                <a href="tel:3277147618"><button style={{ backgroundColor: '#444444', color: '#ffffff' }} >Cell: 327 7147618</button></a>
              </span></strong>
              <div style={{ color: '#7A7A7A' }} >
                Milano<br></br>
                P.Iva e C.F. 09550310966<br></br>
                R.E.A. MI-2098123
              </div>
            </div>
          </Col>

          <Col>
            <div style={{ color: '#94D7B5' }} >PRIVACY & COOKIES</div>
            <ul>
              <li><span style={{ color: '#ffffff' }} ><a style={{ color: '#ffffff' }} href="./condizioni">Condizioni d’uso e Termini del servizio</a></span></li>
              <li><span style={{ color: '#ffffff' }} ><a style={{ color: '#ffffff' }} href="./privacy">Trattamento dei dati personali (Privacy)</a></span></li>
              <li><span style={{ color: '#ffffff' }} ><a style={{ color: '#ffffff' }} href="./cookies">Informazioni Generali sull’Uso dei Cookie</a></span></li>
            </ul>
          </Col>

        </Row>

        <Row>
          <div className='text-center p-2'></div>
          <div style={{ color: '#94D7B5' }} >Copyright © 2023 Noicompriamofarmacie</div>
        </Row>
      <button style={{position: 'fixed', bottom: '20px', right: '25px'}} 
      onClick={() => {removeCookie('CookieConsent'); refresh()}}>Revoca cookies</button>

      </div>
      

      <CookieConsent
          location="bottom"
          style={{ background: "#000", textAlign: "left" }}
          buttonStyle={{ color: "#000", background: "#fff" }}
          buttonText="Do il mio consenso"
          expires={365}
          declineButtonText="Rifiuto il consenso"
          // acceptOnScroll={true}
          // acceptOnScrollPercentage={50}
          // </>onAccept={(byScroll) => {
            // alert(`consent given. \n\n By scrolling? ${byScroll}`);
          // }}
          enableDeclineButton
        >
          Questo sito utilizza cookie tecnici e di profilazione anche di "terze parti" per inviarti pubblicità e servizi in linea con le tue preferenze.
          Per favore leggi la nostra <a href={'/cookies'} style={{ color: '#fff' }}>Cookie Policy</a>.
          Scorrendo questa pagina o cliccando l'apposito bottone acconsenti all'uso di tutti i cookies.
        </CookieConsent>
    </>
  )
}

export default Footer;